import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import {
  FIT_PAGE,
  IN_LINE,
  localeCode,
  PREVIEW,
} from '../../assets/constants/variables';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'Container',
  props: {
    // Here you can define your widget props
    variantId: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'variants-ln2z4eki',
    },
    currentTabValue: {
      type: WidgetPropertyType.STRING,
      defaultValue: PREVIEW,
    },
    url: {
      type: WidgetPropertyType.STRING,
      defaultValue:
        'https://e8f3b9e4-d051-403d-bad0-5f526058fbcc.usrfiles.com/ugd/e8f3b9_eae628cd31c54aaca3721baa28a1af88.pdf',
    },
    embedMode: {
      type: WidgetPropertyType.STRING,
      defaultValue: IN_LINE,
    },
    showPrint: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
    },
    showDownload: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
    },
    fileName: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    action: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'sendingData',
    },
    defaultViewMode: {
      type: WidgetPropertyType.STRING,
      defaultValue: FIT_PAGE,
    },
    selectedViewId: {
      type: WidgetPropertyType.NUMBER,
      defaultValue: 0,
      description: 'This is the description of the prop',
    },
    showAnnotationTools: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: true,
    },
    firstUpload: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: true,
    },
    startPage: {
      type: WidgetPropertyType.NUMBER,
      defaultValue: 1,
    },
    localeLang: {
      type: WidgetPropertyType.STRING,
      defaultValue: localeCode.en,
    },
    setZoomPercentage: {
      type: WidgetPropertyType.NUMBER,
      defaultValue: 0,
    },
  },
  methods: {
    // Here you can define your widget public props
    // handleStateInEditor: {
    //   params: [
    //     {
    //       name: 'handleState',
    //       description: '',
    //     },
    //   ],
    // },
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
