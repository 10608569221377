import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { BUTTON } from '../../assets/constants/variables';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'Widget2',
  props: {
    // Here you can define your widget props
    url: {
      type: WidgetPropertyType.STRING,
      defaultValue:
        'https://e8f3b9e4-d051-403d-bad0-5f526058fbcc.usrfiles.com/ugd/e8f3b9_eae628cd31c54aaca3721baa28a1af88.pdf',
    },
    embedMode: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'LIGHT_BOX',
    },
    currentTabValue: {
      type: WidgetPropertyType.STRING,
      defaultValue: BUTTON,
    },
    printToggle: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
    },
    downloadToggle: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
    },
    fileName: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'Bodea Brochure.pdf',
    },
    defaultViewMode: {
      type: WidgetPropertyType.STRING,
      defaultValue: 'FIT_PAGE',
    },
    showAnnotationTools: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
    },
  },
  methods: {
    // Here you can define your widget public props
    postMessageReader: {
      params: [
        {
          name: 'params',
          description: '',
        },
      ],
    },
    updateEmbedParams: {
      params: [
        {
          name: 'params',
          description: '',
        },
      ],
    },
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
