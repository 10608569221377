import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  widgetBuilder.set({
    displayName: 'Widget Widget1',
    nickname: 'Widget1Widget',
  });
};
