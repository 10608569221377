import {
  fullWindowPreset,
  sizedContainerPreset,
  inLinePreset,
  classic,
  thumbnail,
  button,
} from '../presets-thumbmails/PresetsThumbnails';

export const desktopPresets = [
  {
    name: 'app.settings.gfpp.presets.in.line',
    id: 'variants-lnx8thk81',
    type: 'DESKTOP',
    embedMode: 'IN_LINE',
    state: 'Preview',
    src: inLinePreset,
    width: 980,
    height: 550,
  },

  {
    name: 'app.settings.gfpp.presets.thumbnail',
    id: 'variants-lpqxz59g',
    embedMode: 'LIGHT_BOX',
    state: 'Button',
    src: thumbnail,
    width: 113,
    height: 140,
  },
  {
    name: 'app.settings.gfpp.presets.sized.container',
    id: 'variants-lpqxwgz1',
    type: 'DESKTOP',
    embedMode: 'SIZED_CONTAINER',
    state: 'Preview',
    src: sizedContainerPreset,
    width: 980,
    height: 550,
  },
  {
    name: 'app.settings.gfpp.presets.classic.button',
    id: 'variants-lpqxy51g',
    embedMode: 'LIGHT_BOX',
    state: 'Button',
    src: classic,
    width: 160,
    height: 50,
  },
  {
    name: 'app.settings.gfpp.presets.full.window',
    id: 'variants-lpqxui1x',
    type: 'DESKTOP',
    embedMode: 'FULL_WINDOW',
    state: 'Preview',
    src: fullWindowPreset,
    width: 980,
    height: 550,
  },
  {
    name: 'app.settings.gfpp.presets.document.button',
    id: 'variants-lpqxxk64',
    embedMode: 'LIGHT_BOX',
    state: 'Button',
    src: button,
    width: 113,
    height: 134,
  },
];

export const mobilePresets = [
  {
    name: 'app.settings.gfpp.presets.in.line',
    id: 'variants-lnx8thk81',
    type: 'DESKTOP',
    embedMode: 'IN_LINE',
    state: 'Preview',
    src: inLinePreset,
    width: 320,
    height: 190,
  },

  {
    name: 'app.settings.gfpp.presets.thumbnail',
    id: 'variants-lpqxz59g',
    embedMode: 'LIGHT_BOX',
    state: 'Button',
    src: thumbnail,
    width: 113,
    height: 123,
  },
  {
    name: 'app.settings.gfpp.presets.sized.container',
    id: 'variants-lpqxwgz1',
    type: 'DESKTOP',
    embedMode: 'SIZED_CONTAINER',
    state: 'Preview',
    src: sizedContainerPreset,
    width: 320,
    height: 320,
  },
  {
    name: 'app.settings.gfpp.presets.classic.button',
    id: 'variants-lpqxy51g',
    embedMode: 'LIGHT_BOX',
    state: 'Button',
    src: classic,
    width: 160,
    height: 25,
  },
  {
    name: 'app.settings.gfpp.presets.full.window',
    id: 'variants-lpqxui1x',
    type: 'DESKTOP',
    embedMode: 'FULL_WINDOW',
    state: 'Preview',
    src: fullWindowPreset,
    width: 320,
    height: 440,
  },
  {
    name: 'app.settings.gfpp.presets.document.button',
    id: 'variants-lpqxxk64',
    embedMode: 'LIGHT_BOX',
    state: 'Button',
    src: button,
    width: 113,
    height: 111,
  },
];
