import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
export default createBlocksModel({
  widgetName: 'Widget1',
  props: {
    // Here you can define your widget props
    // url: {
    //   type: WidgetPropertyType.STRING,
    //   defaultValue:
    //     'https://acrobatservices.adobe.com/view-sdk-demo/PDFs/Bodea Brochure.pdf',
    // },
    // embedMode: {
    //   type: WidgetPropertyType.STRING,
    //   defaultValue: 'FULL_WINDOW',
    // },
    // printToggle: {
    //   type: WidgetPropertyType.BOOLEAN,
    //   defaultValue: false,
    // },
    // downloadToggle: {
    //   type: WidgetPropertyType.BOOLEAN,
    //   defaultValue: false,
    // },
    // fileName: {
    //   type: WidgetPropertyType.STRING,
    //   defaultValue: 'Bodea Brochure.pdf',
    // },
  },
  methods: {
    // Here you can define your widget public props
    postMessageReader: {
      params: [
        {
          name: 'params',
          description: '',
        },
      ],
    },
    updateEmbedParams: {
      params: [
        {
          name: 'params',
          description: '',
        },
      ],
    },
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
