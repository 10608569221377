import { desktopPresets } from './presetsId';

export const designPresetsFullPage = (t) => {
  const width = '90%';
  const height = '100%';
  return desktopPresets.map((preset) => ({
    name: t(preset.name),
    id: preset.id,
    type: 'DESKTOP',
    layout: {
      width,
      height,
    },
    src: preset.src,
  }));
};
