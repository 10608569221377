export const PREVIEW = 'Preview';
export const BUTTON = 'Button';
export const allElementsSettingsPanelIds = [
  'radioPageView',
  'sidebarToggle',
  'displayNameTextInput',
  'zoomDropdown',
  'printFile',
  'downloadFile',
  'startNumberPage',
];

export const elementsIdsViewModeFullWindow = [
  'radioPageView',
  'sidebarToggle',
  'displayNameTextInput',
  'zoomDropdown',
  'startNumberPage',
];

export const elementsIdsViewModeInLine = ['startNumberPage'];

export const elementsIdsViewModeSizedContainer = [
  'startNumberPage',
  'displayNameTextInput',
];

export const SIZED_CONTAINER = 'SIZED_CONTAINER';
export const IN_LINE = 'IN_LINE';
export const LIGHT_BOX = 'LIGHT_BOX';
export const ZOOM_TO_PAGE = 'zoom-to-page';
export const FIT_PAGE = 'FIT_PAGE';
export const FIT_TO_WIDTH = 'fit-to-width';
export const FIT_WIDTH = 'FIT_WIDTH';
export const TWO_COLUMN = 'TWO_COLUMN';
export const TWO_COLUMN_FIT_PAGE = 'TWO_COLUMN_FIT_PAGE';
export const SINGLE_PAGE = 'single-page';
export const TWO_PAGE = 'two-page';
export const EXTENSTION_PDF = 'pdf';

export enum viewID {
  'SINGLE_PAGE' = 0,
  'TWO_PAGE' = 1,
}

export enum casePanel {
  MANAGE_LAYOUT = 'ManageLayout',
  MANAGE_SETTINGS = 'ManageSettings',
  MANAGE_PDF = 'ManagePDF',
  MANAGE_DESIGN = 'ManageDesign',
  MANAGE_TEXT = 'ManageText',
}

export const localeCode = {
  da: 'da-DK',
  nl: 'nl-NL',
  en: 'en-US',
  fi: 'fi-FI',
  fr: 'fr-FR',
  de: 'de-DE',
  it: 'it-IT',
  ja: 'ja-JP',
  nb: 'nb-NO',
  pt: 'pt-BR',
  es: 'es-ES',
  sv: 'sv-SE',
  cs: 'cs-CZ',
  ko: 'ko-KR',
  pl: 'pl-PL',
  ru: 'ru-RU',
  tr: 'tr-TR',
  zh: 'zh-CN',
};

export const baseURL =
  'https://e8f3b9e4-d051-403d-bad0-5f526058fbcc.usrfiles.com/ugd/e8f3b9_';
export const pdfLinks = {
  ua: '369262d95b4b48248e5f86f0897a0120',

  zh: '526e78936e034670b9586facf58515f7',

  vi: '7f6edcadefe743fcac7436e1c87671b0',

  pt: '74a50b2501894688a0439a0c528e2234',

  tr: '1a1fed8f3c294515a8c7a4c5227b6f63',

  sv: 'bf9861bd34a742d18a3454b7d4700d4e',

  th: '90cb51ababc84d7685814cc62b4596cc',

  ru: '625dabb213b34c2ab46b681a05fc21a4',

  ko: '6fb7ddfc45d34a12bbfcc5c5300bc125',

  pl: 'e274d4fc8b734ef8b315818728ef4581',

  no: 'c767e78603dc40148e6d5b2e2c43d858',

  nl: '0ec0b115e87f4da2888289cefcd6fca1',

  ja: 'f5f7db42024f4644a7191da083234bd2',

  it: 'a4ae4993612b477582d52bc498c03811',

  id: '993d3ad0fb214bd3ad25e5f4c8911c5a',

  fr: '7ce015cecd8c476c83a6a193fa2a2913',

  es: '2a83de29319d4e7e8c645049797798a9',

  de: 'a7d1b961f5684a929278ffffdbb48184',

  cs: '60424cde9d1845a18048aced287df03c',

  da: '25941ffba92d4cb3b721dbaa49f24169',

  en: 'eae628cd31c54aaca3721baa28a1af88',
};

export const PDF_EXTENSION = '.pdf';
