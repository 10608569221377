import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import {
  IN_LINE,
  PDF_EXTENSION,
  baseURL,
  pdfLinks,
} from '../constants/variables';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';

export const fixTrim = (name, maxLength) => {
  const shortNameParts = name.split('.');
  const extension = shortNameParts.pop();

  if (name.length < maxLength) {
    return { shortName: name, extension };
  }

  let shortName = shortNameParts.join('.');
  shortName =
    shortName.slice(0, maxLength - extension.length - 3) + '...' + extension;
  return { shortName, extension };
};

export const getData = async (editorSDK, compRef) => {
  return editorSDK.document.components.data.get('token', {
    componentRef: compRef,
  });
};

export const updateTextRefData = async (editorSDK, compRef, fileName) => {
  const getTextData = await getData(editorSDK, compRef);
  const getNewTextData = {
    ...getTextData,
    text: `<p class="font_8 wixui-rich-text__text">${fileName}</p>`,
  };
  return editorSDK.document.components.data.update('token', {
    componentRef: compRef,
    data: getNewTextData,
  });
};

export const updateButtonRefData = async (editorSDK, compRef, fileName) => {
  const getButtonData: any = await getData(editorSDK, compRef);
  const getNewButtonData = {
    ...getButtonData,
    label: fileName.fileName,
  };
  return editorSDK.document.components.data.update('token', {
    componentRef: compRef,
    data: getNewButtonData,
  });
};

export const findCompRefButton = async (componentRef, editorSDK) => {
  const rootWidgetParentRef = await editorSDK.document.components.getAncestors(
    '',
    {
      componentRef,
    },
  );
  const regex = /lnx8thkh$/;
  const refItem = rootWidgetParentRef.find((item) =>
    regex.test(item.id),
  ) as ComponentRef;
  return refItem;
};

export const updateDataAfterCloseSettings = async (
  editorSDK,
  foundIds,
  widgetProps,
  myButtonRef,
  myTextRef,
) => {
  const getDataButton: any = await getData(editorSDK, myButtonRef);

  await editorSDK.application.appStudioWidgets.props.set('token', {
    widgetRef: foundIds,
    newProps: {
      ...widgetProps,
      fileName: getDataButton.label,
    },
  });
  await updateTextRefData(editorSDK, myTextRef, getDataButton.label);
};

export const updateFileNameBeforeOpenNativeSettings = async (
  editorSDK,
  widgetProps,
  t,
  myButtonRef,
) => {
  if (widgetProps.fileName === undefined) {
    await editorSDK.document.components.data.update('token', {
      componentRef: myButtonRef,
      data: {
        label: t('app.settings.gfpp.pdf.name'),
      },
    });
  } else {
    await editorSDK.document.components.data.update('token', {
      componentRef: myButtonRef,
      data: {
        label: widgetProps.fileName,
      },
    });
  }
};

export const findDefaultPDFLink = (flowAPI: EditorScriptFlowAPI) => {
  const language = flowAPI.environment.userLanguage;

  const findDefaultLang = pdfLinks[language] ?? pdfLinks.en;

  const defaultPDFLink = baseURL + findDefaultLang + PDF_EXTENSION;

  return defaultPDFLink;
};

export const updateUrlPDF = async (
  widgetRef: ComponentRef,
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
) => {
  const [componentWidgetRef] = await editorSDK.components.getChildren('', {
    componentRef: widgetRef,
  });
  const defaultPDFLink = findDefaultPDFLink(flowAPI);
  await editorSDK.application.appStudioWidgets.props.set('token', {
    widgetRef: componentWidgetRef,
    newProps: {
      url: defaultPDFLink,
      showPrint: false,
      showDownload: false,
      embedMode: IN_LINE,
      startPage: 1,
    },
  });
};

export const findDefaultPDFLinkAfterRemove = (flowAPI) => {
  const language = flowAPI.translations.i18n.language;

  const findDefaultLang = pdfLinks[language] ?? pdfLinks.en;

  const defaultPDFLink = baseURL + findDefaultLang + PDF_EXTENSION;

  return defaultPDFLink;
};

export const debugMessage = (..._: unknown[]) => {}; /// for debug, change this to: debug = console.log
// export const debugMessage = console.log;
